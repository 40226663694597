<template>
    <div v-if="isLoading" class="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
        <div class="flex justify-center items-center mt-[50vh]">
            <div role="status">
                <svg aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <form @submit.prevent="submit" @keydown.enter.prevent="">
                    <div class="grid gap-6 mb-6 md:grid-cols-4">
                        <div>
                            <label for="countries"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                Type</label>
                            <select v-model="form.type" id="countries"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="0" selected>No Promotion</option>
                                <option value="1">Promotion</option>
                            </select>
                        </div>
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                            <input v-model="form.title" type="text" id="first_name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Product Title" required />
                        </div>
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Start
                                Date</label>
                            <div class="relative my-2.5">
                                <VueDatePicker v-model="form.startDate" :format="dateFormat"></VueDatePicker>
                            </div>
                        </div>
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">End
                                Date</label>
                            <div class="relative my-2.5">
                                <VueDatePicker v-model="form.endDate" :format="dateFormat"></VueDatePicker>
                            </div>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Price</label>
                            <input v-model="form.price" type="number" id="first_name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Price" required />
                        </div>
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">End
                                Price</label>
                            <input v-model="form.endPrice" type="number" id="first_name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Promotion Price" required />
                        </div>
                        <div>
                            <label for="first_name" class="block mb-2 text-sm font-medium text-gray-900">Promotion
                                Price</label>
                            <input v-model="form.promotionPrice" type="number" id="first_name"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Promotion Price" required />
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-2">
                        <div>

                            <label for="message"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Description</label>
                            <TinyMCE v-model="form.description" :id="0"></TinyMCE>
                        </div>
                        <div>

                            <label for="message"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Specification</label>
                            <TinyMCE v-model="form.specification" :id="1"></TinyMCE>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <div>
                            <label class=" block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                                for="file_input">
                                Banner (1064 x 260 Pixels)</label>
                            <div>
                                <label class="cursor-pointer">
                                    <img class="h-[260px] w-[2000px] rounded-lg" :src="url_image"
                                        alt="image description">
                                    <input @change="onFileSelectedImage" type="file" class="hidden" />
                                </label>
                            </div>

                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <button type="submit"
                            class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">submit</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

export default {
    data() {
        return {
            isLoading: false,
            url_image: require("@/assets/image-1@2x.jpg"),
            form: {
                type: 0,
                title: "",
                startDate: ref(new Date()),
                endDate: ref(new Date()),
                price: "",
                endPrice: "",
                promotionPrice: "",
                description: "",
                specification: "",
                banner: null,
            },
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Produk", url: "" },
                    { text: "Create", url: "" }
                ]
            },
            dateFormat: 'yyyy-MM-dd',
        }
    },
    methods: {
        onFileSelectedImage(event) {
            const inputElement = event.target
            const file = inputElement.files?.[0]
            this.url_image = file ? URL.createObjectURL(file) : ""
            this.form.banner = file ? file : ""
        },
        submit() {
            const htmlContent1 = this.form.description
            const htmlContent2 = this.form.specification
            const textContent1 = htmlContent1.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()
            const textContent2 = htmlContent2.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (textContent1.length === 0 || textContent2.length === 0 || this.form.banner === null) {
                Swal.fire({
                    title: "Warning",
                    text: " Description or Spesification or Banner cannot be Empty",
                    icon: "warning"
                })
            } else if (this.form.banner.size >= (100 * 1024)) {
                Swal.fire({
                    title: "Warning",
                    text: "File size should not exceed 100 KB",
                    icon: "warning"
                })
            } else {
                const image = new Image();
                image.src = URL.createObjectURL(this.form.banner);
                image.onload = () => {
                    const width = image.naturalWidth;
                    const height = image.naturalHeight;

                    if (width !== 1064 || height !== 260) {
                        Swal.fire({
                            title: "Warning",
                            text: "Image dimensions should be 1064 x 260 pixels",
                            icon: "warning"
                        });
                    } else {
                        Swal
                            .fire({
                                title: "Are you sure?",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Create",
                            })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    this.isLoading = true
                                    this.$store.dispatch('createProduk', {
                                        isPromo: this.form.type,
                                        judulProduk: this.form.title,
                                        startDate: format(new Date(this.form.startDate), 'yyyy-MM-dd'),
                                        endDate: format(new Date(this.form.endDate), 'yyyy-MM-dd'),
                                        price: this.form.price,
                                        endPrice: this.form.endPrice,
                                        promotionPrice: this.form.promotionPrice,
                                        deskripsiProduk: this.form.description,
                                        spesifikasiProduk: this.form.specification,
                                        bannerProduk: this.form.banner
                                    }).then(() => {
                                        Swal.fire({
                                            title: "Create Success",
                                            text: "Sukses Membuat Produk!",
                                            icon: "success"
                                        }).then((result) => {
                                            if (result.isConfirmed) {
                                                this.isLoading = false
                                                location.reload()
                                            }
                                        })
                                    }).catch((error) => {
                                        Swal.fire({
                                            title: "Error",
                                            text: "Gagal membuat Produk: " + error,
                                            icon: "error"
                                        })
                                    })
                                }
                            })
                    }

                }
            }
        }
    }
}
</script>