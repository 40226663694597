import axios from 'axios'
import { Commit } from 'vuex'

const url_api4 = process.env.VUE_APP_API_URL_4

export default {
    namespaced: true,
    state: {
        translator: null
    },
    getters: {
        getTranslator: (state: { translator: any[] }) => state.translator,
    },
    mutations: {
        SET_TRANSLATOR(state: { translator: any[] }, payload: any[]) {
            state.translator = payload;
        },
    },
    actions: {
        async translator({ commit }: { commit: Commit }, payload: { text: any[] }) {
            const { text } = payload

            try {
                await axios.post(url_api4,
                    {
                        text
                    },
                    {
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                )
                    .then((response) => {
                        commit('SET_TRANSLATOR', response.data)
                    })
            } catch (error) {
                return error
            }
        },
    },
}