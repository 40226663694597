<template>
    <div v-if="isLoading" class="w-full h-full fixed top-0 left-0 bg-white opacity-75 z-50">
        <div class="flex justify-center items-center mt-[50vh]">
            <div role="status">
                <svg aria-hidden="true"
                    class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg>
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <nav class="flex justify-between items-center" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                        <span v-if="getCheckOtherLanguageArticleExist === false">
                            <button @click="openModalCreate"
                                class="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                                Create (English Version)</button>
                        </span>
                        <span v-else>
                            <button @click="openModalEdit"
                                class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2">
                                Edit (English Version)</button>
                        </span>
                    </nav>
                </div>
                <form @submit.prevent="submit" @keydown.enter.prevent="">
                    <div class="grid gap-6 mb-6 md:grid-cols-2">
                        <div>
                            <label for="language"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                Content Type</label>
                            <select v-model="form.contentType" id="language"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="1" selected>Artikel</option>
                                <option value="2">Berita</option>
                                <option value="3">Artikel dan Berita</option>
                            </select>
                        </div>
                        <!-- <div>
                            <label for="language"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                Language</label>
                            <select v-model="form.language" id="language"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option value="id" selected>Indonesia</option>
                                <option value="en">English</option>
                            </select>
                        </div> -->
                        <div>
                            <label for="title" class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                            <input v-model="form.title" type="text" id="title"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Title" required />
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-3">
                        <div>
                            <label class="block mb-2 text-sm font-medium text-gray-900">Schedule</label>
                            <div class="relative my-2.5">
                                <VueDatePicker v-model="form.schedule" :format="dateFormat"></VueDatePicker>
                            </div>
                        </div>
                        <div>
                            <label for="author" class="block mb-2 text-sm font-medium text-gray-900">Author</label>
                            <input v-model="form.author" type="text" id="author"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                placeholder="Author" required />
                        </div>
                        <div>
                            <label for="topics"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                Topic</label>
                            <select v-model="form.topic" id="topics"
                                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                <option v-for="(item, index) in topics" :key="index" :value="item.id">{{ item.topik }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <div>
                            <label for="message"
                                class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tags</label>
                            <div
                                class="flex flex-wrap items-center gap-4 border border-gray-300 rounded-lg p-3 overflow-x-auto overflow-y-hidden transition-all ease-in-out duration-150 bg-gray-50">
                                <span v-for="(t, i) in tags" :key="i"
                                    class="flex items-center p-2 bg-sky-300 rounded-lg space-x-2">
                                    <span class="text-gray-800">
                                        {{ t }}
                                    </span>
                                    <XMarkIcon class="w-5 h-5 stroke-gray-600" @click="() => removeTag(i)" />
                                </span>
                                <input v-model="form.tag" type="text"
                                    class="bg-gray-50 flex-grow h-full outline-none border-none border-transparent focus:border-transparent focus:ring-0"
                                    placeholder="tag here..." @keydown="manageKeyPress" />
                            </div>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <div>
                            <label for="message"
                                class="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Description
                                <BibleHint />
                            </label>
                            <TinyMCE v-model="form.content"></TinyMCE>
                        </div>
                    </div>
                    <div class="grid gap-6 mb-6 md:grid-cols-1">
                        <input type="submit"
                            class="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2"
                            value="submit" />
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="flex justify-end">
        <span>
            <div class="fixed inset-0 bg-black bg-opacity-50 z-50" v-if="isModalCreateOpen">
                <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                    <div class="relative p-4 max-w-full w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                        <!-- Modal content -->
                        <div class="relative bg-white rounded-lg shadow-lg max-h-[90vh] overflow-auto">
                            <!-- Modal header -->
                            <div class="flex items-center justify-between p-4 border-b rounded-t">
                                <h3 class="text-xl font-semibold text-gray-900">Create (English Version)</h3>
                                <button type="button" @click="closeModalCreate"
                                    class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                    <svg class="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>
                            <!-- Modal body -->
                            <div class="p-4 space-y-4 max-h-[70vh] overflow-auto">
                                <div>
                                    <div class="max-h-full">
                                        <label class="cursor-pointer">
                                            <div v-if="isLoading" class="flex items-center justify-center w-full h-32">
                                                <svg class="animate-spin h-10 w-10 text-gray-500"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle class="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor" stroke-width="4"></circle>
                                                    <path class="opacity-75" fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <form action="">
                                                <div class="grid gap-6 mb-6 md:grid-cols-2">
                                                    <div>
                                                        <label for="title"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                        <input v-model="form2.title" type="text" id="title"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                            placeholder="Article or News Title" required />
                                                    </div>
                                                    <div>
                                                        <label for="topics"
                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                                            Topic</label>
                                                        <select v-model="form2.topic" id="topics"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <option v-for="(item, index) in topics" :key="index"
                                                                :value="item.id">{{ item.topik }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                    <div>
                                                        <label for="message"
                                                            class="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Description
                                                            <BibleHint />
                                                        </label>
                                                        <TinyMCE v-model="form2.content" :id="0"></TinyMCE>
                                                    </div>
                                                </div>
                                                <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                    <div>
                                                        <label for="message"
                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tags</label>
                                                        <div
                                                            class="flex flex-wrap items-center gap-4 border border-gray-300 rounded-lg p-3 overflow-x-auto overflow-y-hidden transition-all ease-in-out duration-150 bg-gray-50">
                                                            <span v-for="(t, i) in tags2" :key="i"
                                                                class="flex items-center p-2 bg-sky-300 rounded-lg space-x-2">
                                                                <span class="text-gray-800">
                                                                    {{ t }}
                                                                </span>
                                                                <XMarkIcon class="w-5 h-5 stroke-gray-600"
                                                                    @click="() => removeTag2(i)" />
                                                            </span>
                                                            <input v-model="form2.tag" type="text"
                                                                class="bg-gray-50 flex-grow h-full outline-none border-none border-transparent focus:border-transparent focus:ring-0"
                                                                placeholder="tag here..." @keydown="manageKeyPress2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal footer -->
                            <div class="flex items-center p-4 border-t rounded-b">
                                <span>
                                    <button @click="submitCreate" type="button"
                                        class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                        Create
                                    </button>
                                </span>
                                <button @click="closeModalCreate" type="button"
                                    class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
    <div class="flex justify-end">
        <span>
            <div class="fixed inset-0 bg-black bg-opacity-50 z-50" v-if="isModalEditOpen">
                <div aria-hidden="true" class="fixed inset-0 flex items-center justify-center">
                    <div class="relative p-4 max-w-full w-full md:w-3/4 lg:w-2/3 xl:w-1/2">
                        <!-- Modal content -->
                        <div class="relative bg-white rounded-lg shadow-lg max-h-[90vh] overflow-auto">
                            <!-- Modal header -->
                            <div class="flex items-center justify-between p-4 border-b rounded-t">
                                <h3 class="text-xl font-semibold text-gray-900">Edit (English Version)</h3>
                                <button type="button" @click="closeModalEdit"
                                    class="text-gray-400 bg-transparent rounded-lg w-8 h-8">
                                    <svg class="w-3 h-3" aria-hidden="true" fill="none" viewBox="0 0 14 14">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                    </svg>
                                    <span class="sr-only">Close modal</span>
                                </button>
                            </div>
                            <!-- Modal body -->
                            <div class="p-4 space-y-4 max-h-[70vh] overflow-auto">
                                <div>
                                    <div class="max-h-full">
                                        <label class="cursor-pointer">
                                            <div v-if="isLoading" class="flex items-center justify-center w-full h-32">
                                                <svg class="animate-spin h-10 w-10 text-gray-500"
                                                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle class="opacity-25" cx="12" cy="12" r="10"
                                                        stroke="currentColor" stroke-width="4"></circle>
                                                    <path class="opacity-75" fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A8.001 8.001 0 0112 4v4a4 4 0 00-4 4H6v4.291zM20 12a8 8 0 01-8 8v-4a4 4 0 004-4h4zm-2-5.709A8.001 8.001 0 0112 20V16a4 4 0 004-4h4V6.291z">
                                                    </path>
                                                </svg>
                                            </div>
                                            <form action="">
                                                <div class="grid gap-6 mb-6 md:grid-cols-2">
                                                    <div>
                                                        <label for="title"
                                                            class="block mb-2 text-sm font-medium text-gray-900">Title</label>
                                                        <input v-model="form2.title" type="text" id="title"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 light:bg-gray-700 light:border-gray-600 light:placeholder-gray-400 light:text-black light:focus:ring-blue-500 light:focus:border-blue-500"
                                                            placeholder="Article or News Title" required />
                                                    </div>
                                                    <div>
                                                        <label for="topics"
                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Select
                                                            Topic</label>
                                                        <select v-model="form2.topic" id="topics"
                                                            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                            <option v-for="(item, index) in topics" :key="index"
                                                                :value="item.id">{{ item.topik }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                    <div>
                                                        <label for="message"
                                                            class="flex mb-2 text-sm font-medium text-gray-900 dark:text-white">Description
                                                            <BibleHint />
                                                        </label>
                                                        <TinyMCE v-model="form2.content" :id="1"></TinyMCE>
                                                    </div>
                                                </div>
                                                <div class="grid gap-6 mb-6 md:grid-cols-1">
                                                    <div>
                                                        <label for="message"
                                                            class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Tags</label>
                                                        <div
                                                            class="flex flex-wrap items-center gap-4 border border-gray-300 rounded-lg p-3 overflow-x-auto overflow-y-hidden transition-all ease-in-out duration-150 bg-gray-50">
                                                            <span v-for="(t, i) in tags2" :key="i"
                                                                class="flex items-center p-2 bg-sky-300 rounded-lg space-x-2">
                                                                <span class="text-gray-800">
                                                                    {{ t }}
                                                                </span>
                                                                <XMarkIcon class="w-5 h-5 stroke-gray-600"
                                                                    @click="() => removeTag2(i)" />
                                                            </span>
                                                            <input v-model="form2.tag" type="text"
                                                                class="bg-gray-50 flex-grow h-full outline-none border-none border-transparent focus:border-transparent focus:ring-0"
                                                                placeholder="tag here..." @keydown="manageKeyPress2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <!-- Modal footer -->
                            <div class="flex items-center p-4 border-t rounded-b">
                                <span>
                                    <button @click="submitEdit" type="button"
                                        class="text-white bg-blue-800 hover:bg-blue-400 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-400 dark:hover:bg-gray-400 dark:focus:ring-gray-400">
                                        Edit
                                    </button>
                                </span>
                                <button @click="closeModalEdit" type="button"
                                    class="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                                    Decline
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { format } from 'date-fns'
import Swal from 'sweetalert2'

export default {
    components: {
        XMarkIcon,
    },
    data() {
        return {
            isLoading: false,
            isModalCreateOpen: false,
            isModalEditOpen: false,
            url_image: require("@/assets/image-1@2x.jpg"),
            tags: [],
            tags2: [],
            dateFormat: 'yyyy-MM-dd',
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Article and News", url: "" },
                    { text: "Edit", url: "" },
                ]
            },
            form: {
                contentType: "3",
                language: "id",
                title: "",
                schedule: ref(new Date()),
                author: "",
                topic: 1,
                tag: "",
                content: "",
            },
            form2: {
                language: "en",
                title: "",
                content: "",
                topic: 20,
                tag: "",
                languageEN: null
            }
        }
    },
    created() {
        this.$store.dispatch('listTopic', { language: "id" })
        this.$store.dispatch('checkOtherLanguageArticleExist', { id: this.$route.params.idArticle, language: "en" })
        this.detailArticle(this.$route.params.idArticle)
    },
    computed: {
        getDetailArticle() {
            return this.$store.getters.getDetailArticle
        },
        topics() {
            return this.$store.getters.getTopics
        },
        getCheckOtherLanguageArticleExist() {
            return this.$store.getters.getCheckOtherLanguageArticleExist
        },
        getTranslator() {
            return this.$store.getters['translator/getTranslator']
        },
    },
    methods: {
        async openModalCreate() {
            this.isModalCreateOpen = true
            this.$store.dispatch('listTopic', { language: "en" })
            this.form2.title = await this.textToTranslate(this.form.title)
            this.form2.content = await this.textToTranslate(this.form.content)
        },
        closeModalCreate() {
            this.isModalCreateOpen = false
            this.$store.dispatch('listTopic', { language: "id" })
        },
        openModalEdit() {
            this.isModalEditOpen = true
            this.$store.dispatch('listTopic', { language: "en" })
            this.detailArticle2(this.form2.languageEN)
        },
        closeModalEdit() {
            this.isModalEditOpen = false
            this.$store.dispatch('listTopic', { language: "id" })
        },
        removeTag(index) {
            this.tags.splice(index, 1)
        },
        addTag() {
            let v = this.form.tag.trim()
            if (v === '')
                return

            this.tags = [...this.tags, v]
            this.form.tag = ''
        },
        removeTag2(index) {
            this.tags2.splice(index, 1)
        },
        addTag2() {
            let v = this.form2.tag.trim()
            if (v === '')
                return

            this.tags2 = [...this.tags2, v]
            this.form2.tag = ''
        },
        manageKeyPress(e) {
            if (e.key === 'Enter') this.addTag()
            else if (e.key === 'Backspace' && this.form.tag == "") this.tags.pop()
        },
        manageKeyPress2(e) {
            if (e.key === 'Enter') this.addTag2()
            else if (e.key === 'Backspace' && this.form2.tag == "") this.tags2.pop()
        },
        async detailArticle() {
            await this.$store.dispatch('detailArticle', {
                id: this.$route.params.idArticle
            }).then(() => {
                this.isLoadingScreen = true
                this.form.contentType = this.getDetailArticle.jenisContentID
                this.form.language = this.getDetailArticle.language
                this.form.title = this.getDetailArticle.judul
                this.form.schedule = this.getDetailArticle.jadwal
                this.form.author = this.getDetailArticle.author
                this.form.topic = this.getDetailArticle.topikModel.id
                this.tags = this.getDetailArticle.tag
                this.form.content = this.getDetailArticle.deskripsi
                this.form2.languageEN = this.getDetailArticle.languageEN
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        async detailArticle2(id) {
            await this.$store.dispatch('detailArticle', {
                id: id
            }).then(() => {
                this.isLoadingScreen = true
                this.form2.title = this.getDetailArticle.judul
                this.form2.topic = this.getDetailArticle.topikModel.id
                this.tags2 = this.getDetailArticle.tag
                this.form2.content = this.getDetailArticle.deskripsi
            }).finally(() => {
                this.isLoadingScreen = false
            })
        },
        submit() {
            const htmlContent = this.form.content
            const textContent = htmlContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (this.tags.length === 0 || textContent.length === 0) {
                Swal.fire({
                    title: "Warning",
                    text: "Tags or Description or Image cannot be Empty",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoading = true
                            this.$store.dispatch('editArticle', {
                                id: this.$route.params.idArticle,
                                judulArtikel: this.form.title,
                                deskripsiArtikel: this.form.content,
                                authorArtikel: this.form.author,
                                jadwalArtikel: format(new Date(this.form.schedule), 'yyyy-MM-dd'),
                                jenisContent: this.form.contentType,
                                language: this.form.language,
                                topic: this.form.topic,
                                tag: this.tags,
                            }).then(() => {
                                Swal.fire({
                                    title: "Create Success",
                                    text: "Sukses Edit Artikel!",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.isLoading = false
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Edit Artikel: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        submitCreate() {
            const htmlContent = this.form2.content
            const textContent = htmlContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (this.tags2.length === 0 || textContent.length === 0) {
                Swal.fire({
                    title: "Warning",
                    text: "Tags or Descriptioncannot be Empty",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Create",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoadingScreen = true
                            this.$store.dispatch('createArticle2', {
                                id: this.$route.params.idArticle,
                                judulArtikel: this.form2.title,
                                deskripsiArtikel: this.form2.content,
                                language: this.form2.language,
                                topic: this.form2.topic,
                                tag: this.tags2
                            }).then(() => {
                                Swal.fire({
                                    title: "Edit Success",
                                    text: "Sukses Create (English Version) Article or News!",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.isLoadingScreen = false
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Create (English Version) Article or News!: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        submitEdit() {
            const htmlContent = this.form2.content
            const textContent = htmlContent.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '').trim()

            if (this.tags2.length === 0 || textContent.length === 0) {
                Swal.fire({
                    title: "Warning",
                    text: "Tags or Descriptioncannot be Empty",
                    icon: "warning"
                })
            } else {
                Swal
                    .fire({
                        title: "Are you sure?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, Edit",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            this.isLoadingScreen = true
                            this.$store.dispatch('editArticle2', {
                                id: this.form2.languageEN,
                                judulArtikel: this.form2.title,
                                deskripsiArtikel: this.form2.content,
                                topic: this.form2.topic,
                                tag: this.tags2
                            }).then(() => {
                                Swal.fire({
                                    title: "Edit Success",
                                    text: "Sukses Edit (English Version) Article or News!",
                                    icon: "success"
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.isLoadingScreen = false
                                        location.reload()
                                    }
                                })
                            }).catch((error) => {
                                Swal.fire({
                                    title: "Error",
                                    text: "Gagal Edit (English Version) Article or News!: " + error,
                                    icon: "error"
                                })
                            })
                        }
                    })
            }
        },
        async textToTranslate(text) {
            await this.$store.dispatch('translator/translator', {
                text: text
            })
            return this.getTranslator
        },
    },
}
</script>