<template>
    <textarea :id="editorId"></textarea>
</template>

<script>
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver/theme'

export default {
    props: ['modelValue', 'id'],
    computed: {
        editorId() {
            return `tinymce-${this.id}`
        }
    },
    mounted() {
        this.$nextTick(() => {
            tinymce.init({
                selector: `#${this.editorId}`,
                license_key: 'gpl',
                height: 320,
                resize: true,
                forced_root_block: 'p',
                newline_behavior: 'block',
                contenteditable: true,
                plugins: 'code preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap quickbars emoticons',
                toolbar: 'bible_verse | code | undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
                setup: (editor) => {
                    this.editor = editor

                    editor.on('init', () => {
                        editor.setContent(this.modelValue || '')
                    });

                    editor.on('keydown', (event) => {
                        if (event.key === ' ') {
                            event.preventDefault();
                            editor.execCommand('mceInsertContent', false, ' ')
                        }
                    });

                    editor.on('input change keyup', () => {
                        this.$emit('update:modelValue', editor.getContent())
                    })

                    editor.ui.registry.addButton('bible_verse', {
                        text: 'Bible',
                        icon: 'comment',
                        onAction: () => {
                            let modal = document.getElementById('bibleVerseModal')

                            if (!modal) {
                                modal = document.createElement('div');
                                modal.id = 'bibleVerseModal';
                                modal.className = 'fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-50 flex items-center justify-center hidden'
                                modal.innerHTML = `
                                                    <div class="bg-white rounded-lg shadow-lg p-5">
                                                        <h2 class="text-lg font-bold">Masukkan Bible Verse</h2>
                                                        <input id="verseText" type="text" placeholder="Teks Bible Verse" class="mt-2 p-2 border border-gray-300 rounded w-full" />
                                                        <input id="verseLink" type="text" placeholder="URL untuk Bible Verse" class="mt-2 p-2 border border-gray-300 rounded w-full" />
                                                        <div class="mt-4">
                                                            <a id="insertVerse" class="bg-blue-500 text-white rounded px-4 py-2">Simpan</a>
                                                            <a id="closeModal" class="bg-gray-300 text-black rounded px-4 py-2 ml-2">Tutup</a>
                                                        </div>
                                                    </div>
                                                `
                                document.body.appendChild(modal);
                            }

                            modal.classList.remove('hidden')
                            modal.classList.add('flex')
                            document.getElementById('insertVerse').onclick = () => {
                                const verseText = document.getElementById('verseText').value
                                const verseLink = document.getElementById('verseLink').value.toUpperCase()

                                if (verseText) {
                                    editor.insertContent(`<a class="bible-verse" href="${verseLink}">${verseText}</a>`)
                                    editor.insertContent('&nbsp;')
                                    modal.classList.add('hidden')
                                } else {
                                    alert('Teks tidak boleh kosong!')
                                }

                                document.getElementById('verseText').value = '';
                                document.getElementById('verseLink').value = '';
                            }

                            document.getElementById('closeModal').onclick = () => {
                                modal.classList.add('hidden')
                                modal.classList.remove('flex')
                                document.getElementById('verseText').value = ''
                                document.getElementById('verseLink').value = ''
                            }
                        },
                    })
                },
                contextmenu: false
            });
        });
    },
    beforeUnmount() {
        if (this.editor) {
            this.editor.remove()
            this.editor = null
        }
    },
    watch: {
        modelValue(newVal) {
            const editor = this.editor
            if (editor) {
                const currentContent = editor.getContent()
                if (newVal !== currentContent) {
                    if (editor.selection && editor.hasFocus()) {
                        const bookmark = editor.selection.getBookmark(2)
                        editor.setContent(newVal)
                        if (bookmark) {
                            this.$nextTick(() => {
                                editor.selection.moveToBookmark(bookmark)
                            })
                        }
                    } else {
                        editor.setContent(newVal)
                    }
                }
            }
        }
    }
}
</script>

<style>
.tox-tinymce {
    user-select: text !important;
}

.tox-edit-area {
    min-height: 200px !important;
    white-space: normal !important;
}

.tox-tinymce textarea {
    white-space: normal !important;
}
</style>
